import React from "react";
import "../../styles/textla.scss";
import {
  Container,
  Button,
  Jumbotron,
  Image,
  Card,
  Badge,
  Carousel,
  Row,
  Toast
} from "react-bootstrap";
// hero8 best so far, 10,11good too
import textla_hero from "../../images/textla_hero8.png";
import textla_logo from "../../images/textla_logo.png";
import SignUpForm from "../../components/textla/signUpForm";

const messagePreview2 = () => (
  <Container className="panel">
    <Toast>
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
        <strong className="mr-auto">TEST</strong>
        <small>just now</small>
      </Toast.Header>
      <Toast.Body>See? Just like this.</Toast.Body>
    </Toast>
    <Toast>
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
        <strong className="mr-auto">Bootstrap</strong>
        <small>2 seconds ago</small>
      </Toast.Header>
      <Toast.Body>Heads up, toasts will stack automatically</Toast.Body>
    </Toast>
  </Container>
);

export default messagePreview2;
